import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import {Base64UploadAdapter} from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
export default {
    data() {
        return {
            editorData: '<p>Hello, world!</p>',
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            name: '',
            featuredImage: "",
            description: '',
            shortDescription:"",
            price: '',
            productPerCase:'',
            discountPrice: '',
            cost: '',
            sku: '',
            weight: '',
            stock: 1,
            // isReadyToShip: false,
            isDeactive: false,
            color: [],
            colorsList: [],
            brand: {},
            brandList: [],
            offers: [],
            offersList: [],
            previewFeaturedImage: null,
            productImageList: [],
            previewProductImages: [],
            disableButton: false,
            setURL: this.$serverURL + this.$api.product.productURL,
            productList: [],
            saleType:"",
            tiers: [],
            showInitialAddTierButton: true          
        }

    },
    async mounted() {
        await this.getColorVarient();
        await this.getOfferVarient();
        await this.getBrandList();
    },
    methods: {
        uploadFeaturedImage: function () {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.featuredImageInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadProductImages: function () {
            this.productImageList.push(this.$refs.productImages.files[0]);
            // if (this.productImageList.length > 2) {
            //     this.disableButton = true;
            // }
            let input = this.$refs.productImages
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewProductImages.push(e.target.result)
                }
                reader.readAsDataURL(file[0])
            }
            this.$refs.productImages.value = null;
        },
        removeUploadedProductImage: function (index) {
            this.previewProductImages.splice(index, 1);
            this.productImageList.splice(index, 1);
            // if (this.productImageList.length > 3) {
            //     this.disableButton = true;
            // } else {
            //     this.disableButton = false;
            // }
        },
        addNewTier() {
            if (this.showInitialAddTierButton) {
              this.showInitialAddTierButton = false;
            } 
            this.tiers.push({
              numberOfCaseOrProduct: 0,
              tierPrice: 0,
            });
            console.log(this.tiers)    
          },
          deleteTier(index) {
            delete this.tiers[index];
            this.tiers.splice(index, 1);
            if(index===0)
            {
                this.showInitialAddTierButton = true;
            }
        },
        getColorVarient: async function () {
            let url = this.$serverURL + this.$api.product.productColor;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.colorsList = response.data.data.map(el => {
                        return { value: el.colorName, label: el.colorName }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getBrandList: async function () {
            let url = this.$serverURL + this.$api.product.brandURL;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.brandList = response.data.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getOfferVarient: async function () {
            let url = this.$serverURL + this.$api.product.productOffer;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.offersList = response.data.data.map(el => {
                        return { value: el.title, label: el.title }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        checkValidation: function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product name!"
                });
                return false;
            }else if (!this.saleType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select a sale type!"
                });
                return false;
            }else if (this.saleType==="Whole Sale" && !this.productPerCase) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter products per case!"
                });
                return false;
            }else if (this.saleType==="Whole Sale" && this.productPerCase>this.stock) {
                this.$swal.fire({
                    icon: "error",
                    text: "Product per case exceeds stock!"
                });
                return false;
            }
            else if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product description!"
                });
                return false;
            }else if (!this.shortDescription) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product's short description!"
                });
                return false;
            } else if (!this.featuredImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter featured image!"
                });
                return false;
            } else if (this.productImageList.length == 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product image!"
                });
                return false;
            } else if (!this.price) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product price!"
                });
                return false;
            } else if (!this.cost) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product cost!"
                });
                return false;
            } else if (!this.weight) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product weight!"
                });
                return false;
            }
            else {
                return true;
            }

        },
        checkTierValidation: function () {
            if (this.tiers.length > 0) {
                let isValid = true;
        
                this.tiers.forEach((item, index) => {
                    if (item.numberOfCaseOrProduct === 0 || item.tierPrice === 0) {
                        this.$swal.fire({
                            icon: "error",
                            text: `Invalid Tier ${index + 1}`
                        });
                        isValid = false;
                    } else if(item.tierPrice>this.price){
                        this.$swal.fire({
                            icon: "error",
                            text: `Tier ${index + 1} price is greater than unit price !`
                        });
                        isValid = false;
                    } 
                });
        
                return isValid;
            } else {
                return true;
            }
        },
        
        addProduct: async function () { 
            this.$swal.showLoading();
            if (this.checkValidation() && this.checkTierValidation()) {
                let countedPrice;
                if(this.saleType==="Whole Sale"){
                    countedPrice = this.price*this.productPerCase;
                }else{
                    countedPrice = this.price;
                }  
                let formData = new FormData();
                formData.append("name", this.name);
                formData.append("description", this.description);
                formData.append("shortDescription", this.shortDescription);
                formData.append("featuredImage", this.featuredImage);
                formData.append("price", countedPrice);
                if(this.saleType==="Whole Sale"){
                    formData.append("unitPrice", this.price);
                    formData.append("productPerCase",this.productPerCase)
                } else if(this.saleType==="Unit Sale"){
                    formData.append("unitPrice", this.price);
                }
                formData.append("saleType",this.saleType);
                formData.append("tierSale",JSON.stringify(this.tiers));
                formData.append("discountPrice", this.discountPrice);
                formData.append("cost", this.cost);
                formData.append("stock", this.stock);
                formData.append("sku", this.sku);
                formData.append("weight", this.weight);
                formData.append("brandId", this.brand.id);
                formData.append("brandName", this.brand.name);
                this.productImageList.map((el) => {
                    formData.append("productImages", el);
                })
                this.color.map((el) => {
                    formData.append("color[]", el);
                });
                this.offers.map((el) => {
                    formData.append("offers[]", el);
                });
                formData.append("isDeactive", this.isDeactive ? 1 : 0);

                let config = {
                    method: "POST",
                    data: formData,
                    url: this.setURL,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    this.$swal.hideLoading();
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.$router.push("/product-list");
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    if (error.response.data) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }

                });
            } else {
                this.$swal.hideLoading();
                console.log("Something wrong")
            }
        }
    },
}