import { authHeader } from "../../../../../auth";
export default {
    name: "AddNewPackage",
    data() {
        return {
            specificationKey: [],
            specificationValue: [],
            specificationArray: {},
            counter: 1,
            productList: [],
            packageDetails: "",
            productName: "",
        }
    },
    async created() {
        document.title = "Ganja Containers - Add New Package";
        await this.getAllProducts();
    },
    methods: {
        async getAllProducts() {
            let url = this.$serverURL + this.$api.product.productAPI;
            let config = {
              method: "GET",
              url: url,
              headers: {
                "Authorization": authHeader()
              }
            };
      
            try {
              const response = await this.$axios(config);
              if (response.status === 200) {
                this.productList = response.data.data.productData.data;
              } else {
                this.$swal.fire({
                  icon: "error",
                  text: "Failed! Please try again."
                });
              }
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                text: "Failed! Please try again." + error
              });
            }
          },
        addSpecification() {
            let index = this.counter - 1;
            if (!this.specificationKey[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter quantity!"
                });
                return;
            }
            if (!this.specificationValue[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter price!"
                });
                return;
            }
            this.counter = this.counter + 1;
        },
        finalSpecification() {
            if (!this.productName) {
                this.showToaster('Please select product name!')
                return false;
            }
            
            let index = this.counter - 1;
            if (!this.specificationKey[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter quantity!"
                });
                return false;
            }
            if (!this.specificationValue[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter price!"
                });
                return false;
            }
            return true;
        },
        deleteSpecificationRow(index) {
            delete this.specificationArray[this.specificationKey[index]];
            this.specificationKey.splice(index, 1);
            this.specificationValue.splice(index, 1);
            this.counter = this.counter - 1;
        },
        async addEntity() {
            this.specificationArray = {};
            for (let i = 0; i < this.counter; i++) {
              this.specificationArray[this.specificationKey[i]] = this.specificationValue[i];
            }
            // console.log(this.specificationArray);
      
            if (!this.finalSpecification()) {
              return;
            }
      
            this.$swal.showLoading();
            let data = {
                productId: this.productName.id,
                productName: this.productName.name,
                productUrlSlug: this.productName.urlSlug,
                packageDetails: JSON.stringify(this.specificationArray)
            };
      
            let url = this.$serverURL + this.$api.home.homePackagesAPI;
            let config = {
              method: "POST",
              url: url,
              data: data,
              headers: {
                "Authorization": authHeader(),
                "Content-Type": "application/json"
              }
            };
      
            try {
              await this.postDataToBackend(config);
              this.$router.push("/package");
              this.$swal.hideLoading();
              this.showToaster("Package added successfully.", "success");
            } catch (error) {
              this.$swal.hideLoading();
            }
        }
    }
}