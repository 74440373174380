import { authHeader } from "../../../../../auth";
export default {
    name: "Ganja Containers Packages",
    data() {
        return {
            requestUrl: this.$serverURL + this.$api.home.homePackagesAPI,
            dataList: [],
            formData: {
                packageDetails: "",
                productName: "",
            },
            singleData: {
                packageDetails: "",
                productName: "",
            },
        }
    },
    async created() {
        document.title = "Ganja Containers - Packages";
        await this.getData();
        this.loadScripts();
    },
    methods: {
        async getData() {
            await this.getDataList(this.requestUrl).then(response => {
                if (response.statusCode == 200) {
                    this.dataList = response.data;
                }
            });
        },

        checkValidation(data) {
            if (!data.packageDetails) {
                this.showToaster('Please enter price!')
                return false;
            } 
            if (!data.productName) {
                this.showToaster('Please select package name!')
                return false;
            }
            
            return true;
        },

        async createContent() {
            try {
                if (this.checkValidation(this.formData)) {
                    const data = {
                        packageDetails: this.formData.packageDetails,
                        productName: this.formData.productName,

                    }
                    this.$swal.showLoading();
                    await this.postData(this.requestUrl, data).then(async (response) => {
                        if (response.statusCode == 201) {
                            this.formData = {
                                packageDetails: "",
                                productName: "",
                            };
                            this.showToaster(response.message, 'success');
                            await this.getData();
                            document.getElementById('add-close-btn').click();
                        }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        editData(data) {
            this.singleData = { ...data };
        },
        update: async function () {
            if (this.checkValidation(this.singleData)) {
                const data = {
                    packageDetails: this.singleData.packageDetails,
                    productName: this.singleData.productName,
                }
                let url = this.requestUrl + '/' + this.singleData.id;
                this.$swal.showLoading();
                await this.updateData(url, data).then(async (response) => {
                    if (response.statusCode == 200) {
                        this.showToaster(response.message, 'success');
                        this.singleData = {};
                        await this.getData();
                        document.getElementById('edit-close-btn').click();
                    }
                });
            }
        },

        async deleteData(id) {
            const url = this.requestUrl + '/' + id;
            let config = {
              method: "DELETE",
              url: url,
              headers: {
                "Authorization": authHeader(),
                "Content-Type": "application/json"
              }
            };
        
            try {
              await this.deleteDataToBackend(config);
              await this.getData(); // Assuming this method fetches data again after successful deletion
            } catch (error) {
              // Handle error if needed
            }
          }
    }
}