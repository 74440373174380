import { authHeader } from "../../../auth";
import Loading from "vue-loading-overlay";
import Chart from 'chart.js/auto';
// import LinkShortcut from "../../layouts/template/LinkShortcut";

export default {
    components: {
        Loading,
        // LinkShortcut
    },
    data() {
        return {
            dashBoardData: {},
            setURL: this.$serverURL + this.$api.dashBoard.dashboardAPI,
            totalProducts: 0,
            totalCustomers: 0,
            totalBlogs: 0,
            totalOrders: 0,
            totalDeliveredOrders: 0,
            totalCancelledOrders: 0,
            totalPendingOrders: 0,
            todayProducts: [],
            todaysOrders: [],
            chartLoaded: false,
            monthlyCustomer: {},
            topCategories: {}
        }
    },
    async created() {
        document.title = "Ganja Containers - Dashboard";
        await this.getDashBoardData();
        await this.loadJS();
        await this.getBarChart();
        await this.getPieChart();
    },
    methods: {
        async loadJS() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js",
                "/core/assets/plugins/metismenu/js/metisMenu.min.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        async getBarChart() {
            const ctx = document.getElementById('myChart').getContext('2d');
            new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.monthlyCustomer.labels,
                    datasets: [{
                        label: '# of Customer',
                        data: this.monthlyCustomer.data,
                        backgroundColor: [
                            '#3292a8',
                            '#3292a8',
                            '#3292a8',
                            '#3292a8',
                            '#3292a8',
                            '#3292a8',
                            '#3292a8)',
                            '#3292a8',
                            '#3292a8',
                            '#3292a8',
                            '#3292a8',
                            '#3292a8'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    barPercentage: 0.6,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: 'Monthly Customer Data',
                            padding: {
                                top: 10,
                                bottom: 30
                            }
                        }
                    }
                }
            });
        },
        async getPieChart() {
                new Chart(document.getElementById("categoryChart"), {
                    type: 'doughnut',
                    data: {
                        labels: this.topCategories.labels,
                        datasets: [{
                            label: 'Top Products',
                            data: this.topCategories.data,
                            backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(50, 168, 68)',
                            'rgb(50, 131, 168)'
                            ],
                            hoverOffset: 4
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        parsing: {
                            key: 'nested.value'
                        },
                        plugins: {
                            title: {
                                display: true,
                                text: 'Top Three Products',
                                padding: {
                                    top: 10,
                                    bottom: 30
                                }
                            }
                        }
                    }
                });
        },
        async getDashBoardData() {
            const config = {
                method: "GET",
                url: this.setURL,
                headers: {
                  Authorization: authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                   
                    this.totalProducts = response.data.totalProducts;
                    this.totalCustomers = response.data.totalCustomers;
                    this.totalBlogs = response.data.totalBlogs;
                    this.totalOrders = response.data.totalOrders;
                    this.totalDeliveredOrders = response.data.totalDeliveredOrders;
                    this.totalCancelledOrders = response.data.totalCancelledOrders;
                    this.totalPendingOrders = response.data.totalPendingOrders;
                    this.todayProducts = response.data.todayProducts;
                    this.todaysOrders = response.data.todaysOrders;
                    this.monthlyCustomer = response.data.monthlyCustomer;
                    this.topCategories = response.data.topThreeProducts;
                }
                else {
                   
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        }
    },
}