<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Social Links</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Social Link List</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add New Social Link
            </button>
          </div>
          <!-- Add Modal -->
          <div
            class="modal fade"
            id="addModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="addSocialLinks" enctype="multipart/form-data">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Add Social Link</h5>
                    <button
                      type="button"
                      id="addButtonClose"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <!-- <div class="modal-body">
                                        <label class="form-label">Icon Class Name: <a href="https://fontawesome.com/icons" target="_blank">Reference Link</a></label>
                                        <input class="form-control mb-1" type="text" v-model="iconClassName"/>
                  </div>-->
                  <div class="form-group m-3">
                    <label for="exampleInputEmail1">
                      Icon: [<strong>Preferred Icon Size: 20x20, Max Upload Limit: 1MB</strong>]
                    </label>

                    <input
                      class="form-control mb-1"
                      type="file"
                      aria-label="default input example"
                      ref="addImage"
                      @change="handleUploadImage($event)"
                    />
                    <div class="col-12 mt-3">
                      <img
                        style="position: relative; left: 11px"
                        v-if="previewImage"
                        :src="previewImage"
                        width="100"
                      />
                    </div>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Link:</label>
                    <input class="form-control mb-1" type="text" v-model="link" />
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Add Modal -->
          <!-- Edit Modal -->
          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updateSocialLinks">
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Update Social Link</h5>
                    <button
                      type="button"
                      id="editButtonClose"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="form-group m-3">
                    <label for="exampleInputEmail1">
                      Icon: [
                      <strong>Preferred Icon Size: 20x20, Max Upload Limit: 1MB</strong>]
                    </label>

                    <input
                      class="form-control mb-1"
                      type="file"
                      aria-label="default input example"
                      ref="updateImage"
                      @change="handleUploadUpdateImage($event)"
                    />
                    <div class="col-12 mt-3">
                      <img
                        style="position: relative; left: 11px"
                        v-if="previewUpdateImage"
                        :src="previewUpdateImage"
                        height="90"
                      />
                      <img
                        style="position: relative; left: 11px"
                        v-else-if="singleSocialLink.icon"
                        :src="singleSocialLink.icon"
                        height="90"
                      />
                    </div>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Link:</label>
                    <input class="form-control mb-1" type="text" v-model="singleSocialLink.link" />
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Edit Modal -->
        </div>
      </div>
      <h6 class="mb-0">Social Link List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Icon</th>
                  <th>Link</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <img :src="data.icon" height="90"/>
                  </td>
                  <td>{{ data.link }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        v-on:click="editSocialLink(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        class="edit-button"
                      >
                        <i class="bx bxs-edit text-white"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteSocialLinks(data.id)"
                        class="ms-3 delete-button"
                      >
                        <i class="bx bxs-trash text-white"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Icon</th>
                  <th>Link</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/social-links.js'></script>


<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>
