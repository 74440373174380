<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Products
            </li>
          </ol>
        </nav>

        <div class="ms-auto">
          <div class="btn-group">
            <button type="button" class="btn btn-success btn-sm m-1" @click="$router.push('/product-bulk-upload')">
              <i class="bx bxs-plus-square"></i>Import Product
            </button>
            <button type="button" class="btn btn-warning btn-sm m-1" @click="$router.push('/stock-in-product')">
              <i class="bx bxs-plus-square"></i>Stock In Product
            </button>
            <button type="button" class="btn btn-primary btn-sm m-1" @click="$router.push('/add-product')">
              <i class="bx bxs-plus-square"></i>Add New Product
            </button>
            <button type="button" class="btn btn-secondary btn-sm m-1" @click="getExcelProducts">
              <i class="bx bxs-plus-square"></i>Export</button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Product List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Featured Image</th>
                  <th>Price</th>
                  <th>Sale Type</th>
                  <th>Product Per Case</th>
                  <th>Cost</th>
                  <th>Stock</th>
                  <th>IsDeactive</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.name }}</td>
                  <td>
                    <img v-if="data.featuredImage" :src="data.featuredImage" alt=""  width="90" />
                    <img v-else src="../../../../../public/core/assets/images/400.png" alt=""  width="90" />
                  </td>
                  <td>{{ data.unitPrice>0 ? data.unitPrice : data.price }}</td>
                  <td>{{ data.saleType==="" ? "Unit Sale" : data.saleType }}</td>
                  <td>{{ data.saleType==="Whole Sale" && data.productPerCase>0 ? data.productPerCase : "N/A" }}</td>
                  <td>{{ data.cost }}</td>
                  <td>{{ data.stock ? data.stock : 0 }}</td>
                  <td>{{ data.isDeactive ? 'Deactive' : 'Active' }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a href="javascript:void(0);" @click="$router.push('/update-product/' + data.id)"
                        class="edit-button"><i class="bx bxs-edit text-white"></i></a>
                      <!-- <a
                        :href="$hostURL + 'update-product/' + data.id"
                        class="edit-button"
                        ><i class="bx bxs-edit text-white"></i>
                      </a> -->
                      <a href="javascript:void(0);" @click="deleteProduct(data.id)" class="ms-3 delete-button"><i
                          class="bx bxs-trash text-white"></i></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Featured Image</th>
                  <th>Price</th>
                  <th>Sale Type</th>
                  <th>Product Per Case</th>
                  <th>Cost</th>
                  <th>Stock</th>
                  <th>IsDeactive</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/product.js'></script>


<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>