import { authHeader } from "../../../../../auth";

export default {
  name: "EditPackage",
  data() {
    return {
      packageId: "",
      specificationKey: [],
      specificationValue: [],
      specificationArray: {},
      counter: 0,
      productList: [],
      packageDetails: {},
      productName: {},
    };
  },
  async created() {
    document.title = "Ganja Containers - Edit Package";
    await this.getAllProducts();
    this.packageId = this.$route.params.id;
    await this.loadPackageData();
  },
  methods: {
    async getAllProducts() {
      let url = this.$serverURL + this.$api.product.productAPI;
      let config = {
        method: "GET",
        url: url,
        headers: {
          "Authorization": authHeader()
        }
      };

      try {
        const response = await this.$axios(config);
        if (response.status === 200) {
          this.productList = response.data.data.productData.data;
        } else {
          this.$swal.fire({
            icon: "error",
            text: "Failed! Please try again."
          });
        }
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          text: "Failed! Please try again." + error
        });
      }
    },
    async loadPackageData() {
        let url = this.$serverURL + this.$api.home.homePackagesAPI + "/" + this.packageId;
        let config = {
          method: "GET",
          url: url,
          headers: {
            "Authorization": authHeader(),
          },
        };
  
        try {
          const response = await this.$axios(config);
          if (response.status === 200) {
            const packageData = response.data.data;
            // console.log("Package Data:", packageData);
            this.productName = { id: packageData.productId, name: packageData.productName, urlSlug:packageData.productUrlSlug};
            let index = 0;
            for (const [key, value] of Object.entries(JSON.parse(packageData.packageDetails))) {
                this.specificationKey[index] = key;
                this.specificationValue[index] = value;
                this.specificationArray[this.specificationKey[index]] = this.specificationValue[index];
                index++;
                this.counter = this.counter + 1;
            }
            // this.specificationArray = JSON.parse(packageData.packageDetails);
            // this.specificationKey = Object.keys(this.specificationArray);
            // console.log("specificationArray:", this.specificationArray);
            // this.specificationValue = Object.values(this.specificationArray);
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Failed to load package data! Please try again.",
            });
          }
        } catch (error) {
          this.$swal.fire({
            icon: "error",
            text: "Failed to load package data! Please try again. " + error.message,
          });
        }
    },
    addSpecification() {
        let index = this.counter - 1;
        if (!this.specificationKey[index]) {
            this.$swal.fire({
                icon: "error",
                text: "Please enter quantity!"
            });
            return;
        }
        if (!this.specificationValue[index]) {
            this.$swal.fire({
                icon: "error",
                text: "Please enter price!"
            });
            return;
        }
        this.counter = this.counter + 1;
    },
    finalSpecification() {
        if (!this.productName) {
            this.showToaster('Please select product name!')
            return false;
        }
        
        let index = this.counter - 1;
        if (!this.specificationKey[index]) {
            this.$swal.fire({
                icon: "error",
                text: "Please enter quantity!"
            });
            return false;
        }
        if (!this.specificationValue[index]) {
            this.$swal.fire({
                icon: "error",
                text: "Please enter price!"
            });
            return false;
        }
        return true;
    },
    deleteSpecificationRow(index) {
        delete this.specificationArray[this.specificationKey[index]];
        this.specificationKey.splice(index, 1);
        this.specificationValue.splice(index, 1);
        this.counter = this.counter - 1;
    },
    async updateEntity() {
        this.specificationArray = {};
        for (let i = 0; i < this.counter; i++) {
          this.specificationArray[this.specificationKey[i]] = this.specificationValue[i];
        }
      
        if (!this.finalSpecification()) {
          return;
        }
      
        this.$swal.showLoading();
        let data = {
          productId: this.productName.id,
          productName: this.productName.name,
          productUrlSlug: this.productName.urlSlug,
          packageDetails: JSON.stringify(this.specificationArray)
        };
      
        let url = this.$serverURL + this.$api.home.homePackagesAPI + "/" + this.packageId;
        let config = {
          method: "PATCH",
          url: url,
          data: data,
          headers: {
            "Authorization": authHeader(),
            "Content-Type": "application/json"
          }
        };
      
        try {
          await this.postDataToBackend(config);
          this.$router.push("/package"); 
          this.$swal.hideLoading();
          this.showToaster("Package updated successfully.", "success");
        //   console.log();
        } catch (error) {
          this.$swal.hideLoading();
          this.$swal.fire({
            icon: "error",
            text: "Failed! Please try again." + error
          });
        }
      }
  }
};
