import LvColorpicker from 'lightvue/color-picker';

export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            url: this.$serverURL + this.$api.ComponentCms.homeComponentUI,
            cmsData: {},
            dataLoaded: false,
            colors: [
                "#E91E63",
                "#F44336", 
                "#9C27B0", 
                "#673AB7", 
                "#3F51B5", 
                "#2196F3", 
                "#03A9F4", 
                "#00BCD4", 
                "#009688", 
                "#4CAF50", 
                "#8BC34A", 
                "#CDDC39", 
                "#FFEB3B", 
                "#FFC107", 
                "#FF9800", 
                "#795548" 
            ]
        }
    },
    async created() {
        document.title = "Ganja Container - Home ComponentUI CMS";
        await this.getData();
        this.dataLoaded = true;
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response?.data?.statusCode == 200) {
                this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};
            }   
            else {
                this.cmsData = {};
            }
        },
        async update() {
            try {
                let data = {
                    backgroundColor: this.cmsData.backgroundColor ? this.cmsData.backgroundColor : '#FFFFFF',

                    heroTitleColor: this.cmsData.heroTitleColor ? this.cmsData.heroTitleColor : '#00c300',
                    heroButtonTextColor: this.cmsData.heroButtonTextColor ? this.cmsData.heroButtonTextColor : '#FFFFFF',
                    heroButtonBackgroundColor: this.cmsData.heroButtonBackgroundColor ? this.cmsData.heroButtonBackgroundColor : '#ff1082',
                    heroButtonHoverBackgroundColor: this.cmsData.heroButtonHoverBackgroundColor? this.cmsData.heroButtonHoverBackgroundColor : '#ff085f',
                    aboutTitleColor: this.cmsData.aboutTitleColor? this.cmsData.aboutTitleColor : '#000000',
                    aboutHeadingColor: this.cmsData.aboutHeadingColor? this.cmsData.aboutHeadingColor : '#1a1a1a',
                    aboutbuttonTextColor: this.cmsData.aboutbuttonTextColor? this.cmsData.aboutbuttonTextColor : '#FFFFFF',
                    aboutbuttonBgColor: this.cmsData.aboutbuttonBgColor? this.cmsData.aboutbuttonBgColor : '#ff1082',
                    aboutButtonHoverBgColor: this.cmsData.aboutButtonHoverBgColor? this.cmsData.aboutButtonHoverBgColor : '#ff085f',
                    aboutImageTextOneColor: this.cmsData.aboutImageTextOneColor? this.cmsData.aboutImageTextOneColor : '#00c300',
                    bannerTitleColor: this.cmsData.bannerTitleColor? this.cmsData.bannerTitleColor : '#110e02',
                    bannerButtonTextColor: this.cmsData.bannerButtonTextColor? this.cmsData.bannerButtonTextColor : '#110e02',
                    bannerButtonBgColor: this.cmsData.bannerButtonBgColor? this.cmsData.bannerButtonBgColor : '#efc920',
                    discoverSectionTitleColor: this.cmsData.discoverSectionTitleColor? this.cmsData.discoverSectionTitleColor : '#000000',
                    discoverSectionBgColor: this.cmsData.discoverSectionBgColor? this.cmsData.discoverSectionBgColor : '#000000',
                    ourProductHeadingColor: this.cmsData.ourProductHeadingColor? this.cmsData.ourProductHeadingColor : '#000000',
                    ourProductTitleColor: this.cmsData.ourProductTitleColor? this.cmsData.ourProductTitleColor : '#000000',
                    packageTitleColor: this.cmsData.packageTitleColor? this.cmsData.packageTitleColor : '#000000',
                    packageSubTitleColor: this.cmsData.packageSubTitleColor? this.cmsData.packageSubTitleColor : '#000000',
                    blogHeadingColor: this.cmsData.blogHeadingColor? this.cmsData.blogHeadingColor : '#000000',
                    blogTitleColor: this.cmsData.blogTitleColor? this.cmsData.blogTitleColor : '#000000',
                    testimonialSectionBackgroundColor: this.cmsData.testimonialSectionBackgroundColor? this.cmsData.testimonialSectionBackgroundColor : '#f2f2f2',
                    testimonialTitleColor: this.cmsData.testimonialTitleColor? this.cmsData.testimonialTitleColor : '#000000',
                    testimonialDescriptionColor: this.cmsData.testimonialDescriptionColor? this.cmsData.testimonialDescriptionColor : '#000000',
                    instagramHeadingColor: this.cmsData.instagramHeadingColor? this.cmsData.instagramHeadingColor : '#000000',
                    instagramTitleColor: this.cmsData.instagramTitleColor? this.cmsData.instagramTitleColor : '#000000',
                    
                }
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}