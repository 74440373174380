import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            setURL: this.$serverURL + this.$api.blog.singleBlogAPI,
            id: this.$route.params.id,
            previewImage: "",
            singleBlog: {},
        }

    },
    async mounted() {
        await this.getBlogData();
    },
    methods: {
        uploadBlogPhoto: function (event) {
            this.singleBlog.image = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate() {
            if (!this.singleBlog.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog name."
                })
                return false;
            } else if (!this.singleBlog.author) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog author name."
                })
                return false;
            } else if (!this.singleBlog.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog image."
                })
                return false;
            } else if (!this.singleBlog.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog description."
                })
                return false;
            }else if (!this.singleBlog.publishDate) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog publication date."
                })
                return false;
            }
            return true;
        },
        getBlogData: async function () {
            let config = {
                method: "GET",
                url: this.setURL + this.id,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.singleBlog = await response.data.data[0];
                    this.singleBlog.publishDate = this.singleBlog.publishDate.split('T')[0];
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        updateBlogPost: async function () {
            if (this.validate()) {
                try {
                    let formData = new FormData();
                    formData.append('name', this.singleBlog.name)
                    formData.append('author', this.singleBlog.author);
                    formData.append('image', this.singleBlog.image);
                    formData.append('description', this.singleBlog.description);
                    formData.append('publishDate', this.singleBlog.publishDate);
                    formData.append('urlSlug', this.singleBlog.urlSlug);
                    let config = {
                        method: 'PATCH',
                        url: this.setURL + this.singleBlog.id,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.updateDataToBackend(config);
                    this.$router.push("/blog-post");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }

        }
    },
}