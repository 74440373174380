import { authHeader } from '../../../../../auth';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "FAQ",
    data()
    {
        return {
            single: {},
            question: "",
            answer: "",
            setURL: this.$serverURL + this.$api.cms.faq.faqQA,
            dataList: [],
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            }
        }
    },
    async created()
    {
        document.title = "Hyman Fashion - FAQ";
        this.dataList = await this.getDataList(this.setURL);
        console.log(this.dataList);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script =>
        {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        async updateAnswerModalValue(answer) {
            this.answer = answer;
        },
        async updateQuestionModalValue(question) {
            this.question = question;
        },
        addFaq: async function ()
        {
            if (!this.question)
            {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter question!"
                });
            }
            else if (!this.answer)
            {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter answer!"
                });
            }
            else
            {
                let data = {
                    question: this.question,
                    answer: this.answer
                };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        Authorization: authHeader()
                    }

                };
                let response = await this.postData(config);
                if (response.statusCode == 201)
                {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) =>
                        {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.question = '';
                this.answer = '';
                this.dataList = await this.getDataList(this.setURL);
                document.getElementById("closeButton").click();
            }
        },
        editFaq: async function (data)
        {
            this.single = data;
        },
        updateFaq: async function ()
        {
            if (!this.single.question)
            {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter question!"
                });
            }
            else if (!this.single.answer)
            {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter answer!"
                });
            }
            else
            {
                let data = {
                    question: this.single.question,
                    answer: this.single.answer
                };
                let config = {
                    method: "PATCH",
                    url: this.setURL + '/' + this.single.id,
                    data: data,
                    headers: {
                        Authorization: authHeader()
                    }
                };
                let response = await this.updateData(config);
                if (response.statusCode == 200)
                {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) =>
                        {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.single = {};
                this.dataList = await this.getDataList(this.setURL);
                document.getElementById("buttonClose").click();
            }
        },
        deleteFaq: function (id)
        {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) =>
            {
                if (result.isConfirmed)
                {
                    try
                    {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + '/' + id,
                            headers: {
                                Authorization: authHeader()
                            }
                        }
                        await this.deleteData(config);
                        this.dataList = await this.getDataList(this.setURL);
                    } catch (error)
                    {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}