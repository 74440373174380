import ChangePassword from "../template/ChangePassword";
import { authHeader } from "../../../auth";
import { countries } from '../../../../config/country';
export default {
    name: "Profile",
    components: {
        ChangePassword
    },
    data() {
        return {
            userDetails: {},
            id: '',
            email: '',
            isEdit: false,
            countryList: countries,
            country: "",
        }
    },
    async mounted() {
        document.title = "Ganja Container - Profile";
        this.id = localStorage.getItem("id");
        this.email = localStorage.getItem("email");
        await this.getUserInfo();
        this.countryList = countries.map(el => {
            return el.countryNameEn;
        })
    },
    methods: {
        getUserInfo: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.singleUserDetails + this.id,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {

                    this.userDetails = await response.data.data;
                    this.formatDate = new Date(this.userDetails.dateOfBirth);
                    this.userDetails.dateOfBirth = this.formatDate.getFullYear() + '-' + String((this.formatDate.getMonth() + 1)).padStart(2, '0') + '-' + String(this.formatDate.getDate()).padStart(2, '0');

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        save: async function () {
            let data = {
                firstName: this.userDetails.firstName,
                lastName: this.userDetails.lastName,
                email: this.userDetails.email,
                gender: this.userDetails.gender,
                dateOfBirth: this.userDetails.dateOfBirth,
                city: this.userDetails.city,
                postalCode: this.userDetails.postalCode,
                phone: this.userDetails.phone,
                country: this.country ? this.country : this.userDetails.country,
                fullAddress: this.userDetails.fullAddress,
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.user.singleUserDetails + this.id,
                data: data,
                headers: {
                    "Authorization": authHeader(),
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    localStorage.setItem("firstname", this.userDetails.firstName);
                    localStorage.setItem("lastname", this.userDetails.lastName);
                    localStorage.setItem("email", this.userDetails.email);
                    localStorage.setItem("mobile", this.userDetails.mobile);

                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully update admin info!"
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed to update!"
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed to update!" + error
                });
            });

            this.isEdit = false;
        }
    }
}
