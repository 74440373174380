<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add Product
            </li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Add New Product</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-8">
                  <div class="border border-3 p-4 rounded mb-4">
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Product Name:</label>
                      <input type="text" class="form-control" id="inputProductTitle" v-model="name"
                        placeholder="Enter product name" />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductDescription" class="form-label">Short Description:</label>
                      <ckeditor :editor="editor" v-model="shortDescription" :config="editorConfig"></ckeditor>
                    </div>
                    <div class="mb-3">
                      <label for="inputProductDescription" class="form-label">Description:</label>
                      <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                    </div>
                  </div>
                  <div class="border border-3 p-4 rounded mb-3">
                    <label for="exampleInputEmail1">Featured Image:
                      <strong>[Preferred Image Size: 400X400, Max Upload Limit:
                        1MB]</strong></label>
                    <input class="form-control mb-2" type="file" name="featured_image" id="featuredImage"
                      ref="featuredImageInput" aria-describedby="imageHelp" accept="image/*"
                      @change="uploadFeaturedImage()" />
                    <img v-if="previewFeaturedImage" :src="previewFeaturedImage" height="90" />
                    <div id="display_image"></div>
                  </div>
                  <div class="border border-3 p-4 rounded mb-4">
                    <label for="exampleInputEmail1" class="mb-3">Product Images:
                      <strong>[Preferred Image Size: 900X550, Max Upload Limit:
                        1MB]</strong></label>
                    <input type="file" style="display: none" @change="uploadProductImages" ref="productImages" />

                    <div class="card text-center">
                      <div @click="$refs.productImages.click()" class="card-body btn btn-outline-primary">
                        <h5>Select Image</h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-3 mb-2" v-for="(data, index) in previewProductImages" :key="index">
                        <i class="fa-solid fa-circle-xmark close-button" @click="removeUploadedProductImage(index)"></i>
                        <img :src="data" height="90" />
                      </div>
                    </div>
                  </div>
                  <div class="border border-3 p-4 rounded mb-4">
                    <label for="inputCollection" class="form-label">Offers:</label>
                    <Multiselect v-model="offers" mode="tags" :close-on-select="false" :searchable="true"
                      :create-option="true" :options="offersList" />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="border border-3 p-4 rounded">
                    <div class="row g-3">
                      <div>
                        <label class="form-label">Sale Type:</label>
                        <select v-model="saleType" class="form-select mb-3">
                          <option selected style="display: none" value="">
                            Select Sale type
                          </option>
                          <option value="Whole Sale">Whole Sale</option>
                          <option value="Unit Sale">Unit Sale</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label for="inputPrice" class="form-label">{{ saleType === 'Whole Sale' ? "Unit Price" : "Price"
                        }}</label>
                        <input type="number" step="any" class="form-control" id="inputPrice" v-model="price"
                          placeholder="00.00" />
                      </div>
                      <div class="col-md-6" v-if="saleType === 'Whole Sale'">
                        <label for="inputPrice" class="form-label">Product Per Case:</label>
                        <input type="number" step="any" class="form-control" id="inputPrice" v-model="productPerCase"
                          placeholder="00.00" />
                      </div>
                      <div class="col-md-6">
                        <label class="form-label">Discount Price</label>
                        <input type="number" step="any" class="form-control" pattern="[0-9]*" v-model="discountPrice"
                          placeholder="00.00" />
                      </div>
                      <div class="col-md-6">
                        <label class="form-label">Cost</label>
                        <input type="number" step="any" class="form-control" pattern="[0-9]*" v-model="cost"
                          placeholder="00.00" />
                      </div>

                      <div class="col-md-6">
                        <label for="inputPrice" class="form-label">Stock:</label>
                        <input type="number" class="form-control" id="inputPrice" v-model="stock" placeholder="stock" />
                      </div>
                      <div class="col-md-6">
                        <label for="inputStarPoints" class="form-label">SKU:</label>
                        <input type="text" class="form-control" id="inputStarPoints" v-model="sku" placeholder="SKU" />
                      </div>
                      <div class="col-md-6">
                        <label for="inputStarPoints" class="form-label">Weight:</label>
                        <input type="number" step="0.01" class="form-control" id="inputStarPoints" v-model="weight"
                          placeholder="Weight" />
                      </div>
                      <div>
                        <button v-if="showInitialAddTierButton" type="button" @click="addNewTier"
                          class="btn btn-primary mt-2">
                          Add Tier
                        </button>
                        <div v-for="(tier, index) in tiers" :key="index" class="mt-4">
                          <div class="d-flex flex-row justify-content-between align-items-center gap-2">
                            <h6 style="font-weight: bold;">Tier {{ index + 1 }}</h6>
                            <button @click="deleteTier(index)" type="button"
                              class="btn btn-danger">X</button>
                          </div>
                          <hr>
                          <div class="col-md-6">
                            <label for="inputStarPoints" class="form-label">Number of {{ saleType==="Whole Sale" ? "Case":"Product" }}:</label>
                            <input type="number" step="1" class="form-control" v-model="tier.numberOfCaseOrProduct" />
                          </div>
                          <div class="col-md-6">
                            <label for="inputStarPoints" class="form-label">Price:</label>
                            <input type="number" step="0.01" class="form-control" v-model="tier.tierPrice" />
                          </div>
                          <button v-if="index === tiers.length - 1" type="button" @click="addNewTier"
                            class="btn btn-primary mt-2">
                            Add Tier
                          </button>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="row">
                          <div class="col-sm-12">
                            <label for="inputCollection" class="form-label">Brand:</label>
                            <select v-model="brand" class="form-select form-control mb-3">
                              <option class="p-1" v-for="(row, index) in brandList" :key="index"
                                :value="{ id: row.id, name: row.brandName }">
                                {{ row.brandName }}
                              </option>
                            </select>
                          </div>
                          <div class="col-sm-12">
                            <label for="inputCollection" class="form-label">Colors:</label>
                            <Multiselect v-model="color" mode="tags" :close-on-select="false" :searchable="true"
                              :create-option="true" :options="colorsList" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Is Deactive: </label>
                          <div class="form-check form-switch mt-1">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                              v-model="isDeactive" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-grid">
                          <button type="button" @click="addProduct" class="btn btn-primary">
                            Save Product
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src='../js/add-product.js'></script>

<style scoped>
@import "../css/add-product.css";
</style>