import { authHeader } from "../../../auth";

export default {
    data() {
        return {
            url: this.$serverURL + this.$api.taxExempt.unapprovedURL
        }
    },
    async created() {
        document.title = "HGS - Tax Exempt";
        await this.getDataList(this.url);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        approve: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "This user will be approved!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Approve'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        let config = {
                            method: "PATCH",
                            url: this.$serverURL + this.$api.taxExempt.doApproveURL + '/' + id,
                            headers: {
                                "Authorization": authHeader()
                            }
                        };
                        this.$axios(config).then(async (response) => {
                            if(response.status == 200) {
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: "User approved successfully.",
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                      toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                      toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                });
                            }
                            this.isLoading = false;
                            await this.getDataList(this.url);
                        }).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    }
                })
        },
        reject: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "This user will be rejected!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Rejected'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        let config = {
                            method: "PATCH",
                            url: this.$serverURL + this.$api.taxExempt.doRejectURL + '/' + id,
                            headers: {
                                "Authorization": authHeader()
                            }
                        };
                        this.$axios(config).then(async (response) => {
                            if(response.status == 200) {
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: "User rejected successfully.",
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                      toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                      toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                });
                            }
                            this.isLoading = false;
                            await this.getDataList(this.url);
                        }).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    }
                })
        },
    }
}