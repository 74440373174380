import { authHeader } from "../../../auth";
export default {
    name: "AddDiscountModal",
    props: ['userData', 'getDataList'],
    data() {
        return {
            discountType: 'Amount',
            discountAmount: '',
            userId: '',
            firstName: '',
            lastName: '',
            mobile: '',
            gender: '',
            discountSymbol: '$'
        }
    },
    watch: {
        userData() {
            let data = this.$props.userData;
            this.userId = data.id;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.mobile = data.mobile;
            this.gender = data.gender;
            this.discountType = data.discountType;
            this.discountAmount = data.discountAmount;
        }
    },
    methods: {
        onDiscountType(event) {
            if (event.target.value == 'Percentage') {
                this.discountSymbol = '%';
            } else {
                this.discountSymbol = '$';
            }
        },
        update: async function () {
            let data = {
                discountType: this.discountType,
                discountAmount: this.discountAmount,
                firstName : this.firstName,
                lastName : this.lastName,
                mobile : this.mobile,
                gender : this.gender
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.user.userProfile + '/' + this.userId,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully update user data!"
                    });
                    this.getDataList();
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Failed to update user data!"
                });
            });
        }
    }
}
