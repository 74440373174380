import { authHeader } from "../../../auth";
import StarRating from 'vue-star-rating';

export default {
    name: "Testimonial",
    components: {
        StarRating
    },
    data() {
        return {
            setURL: this.$serverURL + this.$api.testimonial.testimonialAPI,
            singleData: {},
            name: "",
            review: "",
            rating: "",
            image: '',
            previewImage:'',
            updatePreviewImage:''
        }
    },
    async created() {
        document.title = "Ganja Containers - Testimonial";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        uploadFeaturedImage: function(event) {
            this.image = event.target.files[0];
            let input = this.$refs.blockImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateFeaturedImage: function(event) {
            this.updateImage = event.target.files[0];
            let input = this.$refs.blockUpdateImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = e => {
                    this.updatePreviewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        addHeroSection: async function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter name!"
                });
            } else if (!this.review) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter review!"
                });
            } else if (!this.rating) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter rating!"
                });
            } else if (!this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter image!"
                });
            }
            else {
                let formData = new FormData();
                formData.append('name', this.name);
                formData.append('review', this.review);
                formData.append('rating', this.rating);
                formData.append('image', this.image);
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data",
                    }
                };
                await this.postDataToBackend(config);
                this.$refs.blockImage.value = null;
                this.previewImage = '';
                this.title = '';
                this.description = '';
                this.image = '';
                await this.getDataList(this.setURL);

                window.$("#addModal").modal('hide');
            }
        },
        editHeroSection: async function (data) {
            this.singleData = data;
        },
        updateHeroSection: async function () {
            if (!this.singleData.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter name!"
                });
            } else if (!this.singleData.review) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter review!"
                });
            } else if (!this.singleData.rating) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter rating!"
                });
            }  else if (!this.singleData.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter image!"
                });
            }
            else {
                let formData = new FormData();
                formData.append('name', this.singleData.name);
                formData.append('review', this.singleData.review);
                formData.append('rating', this.singleData.rating);
                formData.append('image', this.updateImage ? this.updateImage : this.singleData.image);
                let config = {
                    method: "PATCH",
                    url: `${this.setURL}${this.singleData.id}` ,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data",
                    }
                };
                await this.updateDataToBackend(config);
                this.$refs.blockUpdateImage.value = null;
                this.singleData = {};
                this.currentImage = '';
                this.updatePreviewImage = '';
                this.previewImage = '';
                await this.getDataList(this.setURL);
                window.$("#editModal").modal('hide');
            }
        },
        deleteHeroSection: function (id) {
            this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: `${this.setURL}${id}`,
                                headers: {
                                    "Authorization": authHeader(),
                                }
                            }
                            await this.deleteDataToBackend(config);
                            await this.getDataList(this.setURL);

                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong, please try agian later." + error
                            });
                        }
                    }
                }
            })

        }
    }
}