<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Tax Exempt List
                        </li>
                    </ol>
                </nav>
            </div>
            <h6 class="mb-0 text-uppercase">Rejected Tax Exempt List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>User Name</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.userName }}</td>
                                    <td>{{ $filters.formatDate(data.createdAt) }}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a :href="data.taxFile" class="edit-button btn-sm ms-3"><i class="bx bx-cloud-download font-22 me-0"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>User Name</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/rejected-tax-exempt.js'></script>

<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
    background-color: #17a00e;
}
.delete-button {
    background-color: #f41127;
}
.approve-button {
    background-color: #17a00e;
}
</style>
