// import { authHeader } from "../../../auth";

export default {
    data() {
        return {
            url: this.$serverURL + this.$api.taxExempt.approvedURL
        }
    },
    async created() {
        document.title = "HGS - Tax Exempt";
        await this.getDataList(this.url);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    }
}