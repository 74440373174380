

export default {
    data() {
        return {
            setURL: '',
            pageCMSData: {
                homePageTitle: "",
                shopPagetitle: "",
                blogPageTitle: "",
                aboutPageTitle: "",
                helpAndSupportPageTitle: "",
                accountPageTitle: "",
                faqPageTitle: "",
                privacyPageTitle: "",
                termsPageTitle: "",
                cartPageTitle: "",
                wishlistPageTitle: "",
                checkoutPageTitle: "",
                dashboardPageTitle: "",
                orderHistoryPageTitle: "",
                transactionsPageTitle: "",
                addressPageTitle: "",
                taxExemptPageTitle: "",
                resetPassPageTitle: "",
                registerPageTitle: "",
                loginPageTitle: "",
                forgetPassPageTitle: "",
                changePassPageTitle: "",
                confirmOrderPageTitle: ""
            }
        }
    },
    async mounted() {
        document.title = "Ganja Container - Page Title";
        this.setURL = this.$serverURL + this.$api.cms.pageTitleCMS;
        const res = await this.getCmsData(this.setURL);
        if (res.data.statusCode === 200) {
            this.pageCMSData = res.data.data[0];
        }
         else {
            this.pageCMSData = {
                homePageTitle: "",
                shopPageTitle: "",
                blogPageTitle: "",
                aboutPageTitle: "",
                helpAndSupportPageTitle: "",
                accountPageTitle: "",
                faqPageTitle: "",
                privacyPageTitle: "",
                termsPageTitle: "",
                cartPageTitle: "",
                wishlistPageTitle: "",
                checkoutPageTitle: "",
                dashboardPageTitle: "",
                orderHistoryPageTitle: "",
                transactionsPageTitle: "",
                addressPageTitle: "",
                taxExemptPageTitle: "",
                resetPassPageTitle: "",
                registerPageTitle: "",
                loginPageTitle: "",
                forgetPassPageTitle: "",
                changePassPageTitle: "",
                confirmOrderPageTitle: ""
            };
        }
    },
    methods: {
        async update() {
            this.$swal.showLoading();
            try {
                let data = {
                    homePageTitle: this.pageCMSData.homePageTitle,
                    shopPageTitle: this.pageCMSData.shopPageTitle,
                    blogPageTitle: this.pageCMSData.blogPageTitle,
                    aboutPageTitle: this.pageCMSData.aboutPageTitle,
                    helpAndSupportPageTitle: this.pageCMSData.helpAndSupportPageTitle,
                    accountPageTitle: this.pageCMSData.accountPageTitle,
                    faqPageTitle: this.pageCMSData.faqPageTitle,
                    privacyPageTitle: this.pageCMSData.privacyPageTitle,
                    termsPageTitle: this.pageCMSData.termsPageTitle,
                    cartPageTitle: this.pageCMSData.cartPageTitle,
                    wishlistPageTitle: this.pageCMSData.wishlistPageTitle,
                    checkoutPageTitle: this.pageCMSData.checkoutPageTitle,
                    dashboardPageTitle: this.pageCMSData.dashboardPageTitle,
                    orderHistoryPageTitle: this.pageCMSData.orderHistoryPageTitle,
                    transactionsPageTitle: this.pageCMSData.transactionsPageTitle,
                    addressPageTitle: this.pageCMSData.addressPageTitle,
                    taxExemptPageTitle: this.pageCMSData.taxExemptPageTitle,
                    resetPassPageTitle: this.pageCMSData.resetPassPageTitle,
                    registerPageTitle: this.pageCMSData.registerPageTitle,
                    loginPageTitle: this.pageCMSData.loginPageTitle,
                    forgetPassPageTitle: this.pageCMSData.forgetPassPageTitle,
                    changePassPageTitle: this.pageCMSData.changePassPageTitle,
                    confirmOrderPageTitle: this.pageCMSData.confirmOrderPageTitle
                }
                let url = this.$serverURL + this.$api.cms.pageTitleCMS;

               const res =  await this.createUpdateCMS(url, data);
               if(res.statusCode===200){
                this.$swal.hideLoading();
                this.$swal.fire({
                    icon: "success",
                    text: "Data Updated Successfully"
                });
               }
               await this.getCmsData(this.setURL);
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}