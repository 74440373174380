import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            featuredImage: "",
            data: [],
            color: [],
            colorsList: [],
            offers: [],
            offersList: [],
            brand: {},
            brandList: [],
            previewFeaturedImage: null,
            productImageList: [],
            previewProductImages: [],
            disableButton: false,
            id: this.$route.params.id,
            siteUrl: this.$serverURL + this.$api.product.productURL,
            imageUrl: this.$imageURL,
            singleProduct: {},
            deletedImage: [],
            description: "",
            shortDescription:"",
            tiers: [],
            showInitialAddTierButton: true
        }

    },
    async mounted() {
        await this.getColorVarient();
        await this.getOfferVarient();
        await this.getBrandList();
        await this.getProductDetails();
    },
    methods: {
        checkName(data) {
            if (data.startsWith("https")) {
                return true;
            } else {
                return false;
            }
        },
        getProductDetails: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.siteUrl + '/' + this.id,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data?.data) {
                            this.singleProduct = response.data.data;
                            if (this.singleProduct.name) {
                                this.name = this.singleProduct.name;
                            }
                            if (this.singleProduct.description) {
                                this.description = this.singleProduct.description;
                            }
                            if (this.singleProduct.shortDescription) {
                                this.shortDescription = this.singleProduct.shortDescription;
                            }
                            if(this.singleProduct.tierSale){
                                this.tiers = JSON.parse(this.singleProduct.tierSale)
                                if(this.tiers.length>0) this.showInitialAddTierButton = false;
                            }
                            this.productImageList = this.singleProduct.imageGallery.map(el => {
                                return el.image;
                            });
                            this.previewProductImages = this.singleProduct.imageGallery.map(el => {
                                return el.image;
                            });
                            this.color = this.singleProduct.colorVariants.map(el => {
                                return el.colorName;
                            });
                            this.offers = this.singleProduct.offerVariants.map(el => {
                                return el.offerTitle;
                            });
                            this.brand = {
                                id: this.singleProduct.brandId,
                                name: this.singleProduct.brandName,
                            }
                        }
                    }
                }).catch(error => {
                    console.log(error.response.data.message);
                });

            } catch (error) {
                console.log(error);
            }
        },
        uploadFeaturedImage() {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.featuredImageInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadProductImages() {
            this.productImageList.push(this.$refs.productImages.files[0]);
            // if (this.productImageList.length > 2) {
            //     this.disableButton = true;
            // }
            let input = this.$refs.productImages
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewProductImages.push(e.target.result)
                }
                reader.readAsDataURL(file[0])
            }
            this.$refs.productImages.value = null;
        },
        addNewTier() {
            if (this.showInitialAddTierButton) {
              this.showInitialAddTierButton = false;
            } 
            this.tiers.push({
              numberOfCaseOrProduct: 0,
              tierPrice: 0,
            });
            console.log(this.tiers)    
          },
          deleteTier(index) {
            delete this.tiers[index];
            this.tiers.splice(index, 1);
            if(index===0)
                {
                    this.showInitialAddTierButton = true;
                }
        },
        getColorVarient: async function () {
            let url = this.$serverURL + this.$api.product.productColor;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.colorsList = response.data.data.map(el => {
                        return { value: el.colorName, label: el.colorName }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getBrandList: async function () {
            let url = this.$serverURL + this.$api.product.brandURL;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.brandList = response.data.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getOfferVarient: async function () {
            let url = this.$serverURL + this.$api.product.productOffer;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.offersList = response.data.data.map(el => {
                        return { value: el.title, label: el.title }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        validate: function () {
            if (!this.singleProduct.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product name!"
                });
                return false;
            }else if (!this.singleProduct.saleType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select a sale type!"
                });
                return false;
            }else if (this.singleProduct.saleType==="Whole Sale" && !this.singleProduct.productPerCase) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter products per case!"
                });
                return false;
            }else if (this.singleProduct.saleType==="Whole Sale" && this.singleProduct.productPerCase>this.singleProduct.stock) {
                this.$swal.fire({
                    icon: "error",
                    text: "Product per case exceeds stock!"
                });
                return false;
            }
            else if (!this.singleProduct.featuredImage && !this.featuredImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter featured image!"
                });
                return false;
            } else if (!this.singleProduct.price) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product price!"
                });
                return false;
            } 
            else if (!this.singleProduct.cost) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product cost!"
                });
                return false;
            }
            else if (!this.singleProduct.weight) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product weight!"
                });
                return false;
            }else if (this.singleProduct.saleType==="Whole Sale" && this.singleProduct.discountPrice > this.singleProduct.unitPrice * this.singleProduct.productPerCase) {
                this.$swal.fire({
                    icon: "error",
                    text: "Discount Price exceeds the actual price!"
                });
                return false;
            }else if (this.singleProduct.saleType==="Unit Sale" && this.singleProduct.discountPrice > this.singleProduct.unitPrice) {
                this.$swal.fire({
                    icon: "error",
                    text: "Discount Price exceeds the actual price!"
                });
                return false;
            }
            return true;
        },
        checkTierValidation: function () {
            if (this.tiers.length > 0) {
                let isValid = true;
        
                this.tiers.forEach((item, index) => {
                    if (item.numberOfCaseOrProduct === 0 || item.tierPrice === 0) {
                        this.$swal.fire({
                            icon: "error",
                            text: `Invalid Tier ${index + 1}`
                        });
                        isValid = false;
                    } else if(item.tierPrice>this.singleProduct.unitPrice){
                        this.$swal.fire({
                            icon: "error",
                            text: `Tier ${index + 1} price is greater than unit price !`
                        });
                        isValid = false;
                    }
                });
        
                return isValid;
            } else {
                return true;
            }
        },
        updateProduct: async function () {
            this.$swal.showLoading();
            if (this.validate() && this.checkTierValidation()) {
                let countedPrice;
                if(this.singleProduct.saleType==="Whole Sale"){
                    countedPrice = this.singleProduct.unitPrice*this.singleProduct.productPerCase;
                }else if(this.singleProduct.saleType==="Unit Sale"){
                    countedPrice = this.singleProduct.unitPrice;
                }
                let formData = new FormData();
                formData.append("name", this.singleProduct.name);
                formData.append("description", this.description);
                formData.append("shortDescription", this.shortDescription);
                formData.append("price", countedPrice);
                formData.append("unitPrice", this.singleProduct.unitPrice);
                if(this.singleProduct.saleType==="Whole Sale"){
                    formData.append("productPerCase",this.singleProduct.productPerCase)
                }
                formData.append("tierSale", JSON.stringify(this.tiers));
                formData.append("saleType",this.singleProduct.saleType);
                formData.append("discountPrice", this.singleProduct.discountPrice);
                formData.append("stock", this.singleProduct.stock);
                formData.append("cost", this.singleProduct.cost);
                formData.append("sku", this.singleProduct.sku);
                formData.append("weight", this.singleProduct.weight);
                formData.append("isDeactive", this.singleProduct.isDeactive ? 1 : 0);
                // formData.append("isReadyToShip", this.singleProduct.isReadyToShip ? 1 : 0);
                if (this.featuredImage) {
                    formData.append("featuredImage", this.featuredImage);
                } else {
                    formData.append("featuredImage", this.singleProduct.featuredImage);
                }
                this.productImageList.map((el) => {
                    formData.append("productImages", el);
                });
                this.deletedImage.map((el) => {
                    formData.append("deletedImage", el);
                });
                this.color.map((el) => {
                    formData.append("color[]", el);
                });
                this.offers.map((el) => {
                    formData.append("offers[]", el);
                });
                formData.append("brandId", this.brand.id);
                formData.append("brandName", this.brand.name);
                let config = {
                    method: "PATCH",
                    data: formData,
                    url: this.siteUrl + '/' + this.id,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    this.$swal.hideLoading();
                    if (response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.$router.push("/product-list");
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again." + error
                    });
                });
            }
        },
        removeUploadedProductImage(index) {
            this.previewProductImages.splice(index, 1);
            if (typeof this.productImageList[index] === "string") {
                if (this.productImageList[index].startsWith("https")) {
                    this.deletedImage.push(this.productImageList[index]);
                }
            }
            this.productImageList.splice(index, 1);
            // if (this.productImageList.length > 3) {
            //     this.disableButton = true;
            // } else {
            //     this.disableButton = false;
            // }
        }
    }
}