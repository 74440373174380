import { authHeader } from "../../../../auth";
import { countries } from "../../../../../config/country";
import { states } from '../../../../../config/states-list';
export default {
    data() {
        return {
            states: states,
            productValue: [],
            selectedProduct: [],
            productURL: this.$serverURL + this.$api.product.productAPI,
            productAuthorizedURL: this.$serverURL + this.$api.product.productAuthorizedAPI,
            addedProductList: [{
                productId: ""
            }],
            confirmedProduct: [],
            colorValue: [''],
            subTotal: 0,
            grandTotal: 0,
            isExistingUser: null,
            setURL: this.$serverURL + this.$api.user.customerUserList,
            userList: [],
            userDetails: "",
            userName: "",
            userEmail: "",
            userMobile: "",
            shippingName: "",
            shippingEmail: "",
            shippingAddress1: "",
            shippingAddress2: "",
            shippingContactNo: "",
            shippingCountry: {
                name: 'United States of America',
                code: 'US',
            },
            shippingPostCode: "",
            stateCode: "",
            shippingCity: "",
            remarks: "",
            productList: [],
            discount: 0,
            total: 0,
            idx: 0,
            priceList: [],
            countries: countries,
            countryList: [],
            shippingType: null,
            serviceTypeList: [],
            serviceType: "",
            packageType: "",
            shippingRemarks: "",
            totalWeight: 0,
            shippingAddressData: {},
            shippingCost: 0,
            userID: '',
            userDiscountType: null,
            userDiscountAmount: null,
            page: 1,
            limit: 10,
            salesTax: {},
            totalDiscount: 0,
            manualDiscount: 0,
            finalAmount: 0,
            taxAmount: 0,
            orderId: '',
            finalOrderID: '',
            paymentInfo: {
                name: "",
                cardNumber: "",
                securityCode: "",
                amount: "",
                expireDate: "",
            },
            orderConfirmed: false,
            paymentMethodName: "",
            paymentStatus: "",
            shippingMethodList: [],
            shippingCmsData: {},
            imagePath: this.$imageURL + 'shipping-methods/',
        }
    },
    async created() {
        document.title = "Ganja Containers - Create Order";
        await this.getDataList(this.productURL);
        await this.getSalesTax();
        await this.getOrderId();
        await this.shippingCms();
        this.productList = this.dataList?.productData?.data?.map(el => {
            return { value: el, label: el.name }
        })
        this.countryList = this.countries.map(el => {
            return { value: { name: el.countryNameEn, code: el.countryCode }, label: el.countryNameEn }
        })
        await this.getCustomerList();
    },
    methods: {
        async shippingMethods() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.payment.paymentMethodAPI,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.state.isLoading = true;
            await this.$axios(config).then(response => {
                if (response.data.statusCode == 200) {
                    this.shippingMethodList = response.data.data;
                }
            }).catch(error => {
                console.log(error)
            });

        },
        async getOrderId() {
            try {
                let config = {
                    method: 'GET',
                    url: this.$serverURL + this.$api.order.getOrderIdURL,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        let orderId = response.data;
                        this.finalOrderID = orderId;
                        orderId = orderId.substring(3);
                        this.orderId = orderId;
                    }
                }).catch(error => {
                    console.log(error)
                })
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        async checkOrderId() {
            try {
                let orderText = "HGS";
                let id = orderText.concat(this.orderId);
                let config = {
                    method: 'GET',
                    url: this.$serverURL + this.$api.order.checkOrderIdURL + '/' + id,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        if (response.data == true) {
                            this.$swal.fire({
                                icon: "error",
                                text: "The order ID has been duplicated, please insert a new order ID!"
                            });
                        }
                        else {
                            this.finalOrderID = id;
                        }
                    }
                }).catch(error => {
                    console.log(error)
                })
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        async getSalesTax() {
            try {
                let config = {
                    method: 'GET',
                    url: this.$serverURL + this.$api.salesTax.salseTaxCMS,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        this.salesTax = response.data.data.length > 0 ? response.data.data[0] : {};
                    }
                }).catch(error => {
                    console.log(error)
                })
            } catch (error) {
                console.log(error);
            }
        },
        setShippingAddressOne(locationData) {
            let location = locationData.formatted_address;
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.shippingAddressData.addressOne = locationData.name;

            this.shippingAddressData.city = "";
            this.shippingAddressData.postCode = "";
            this.shippingAddressData.stateCode = "";

            for (let i = 0; i < addressComponent.length; i++) {
                if (addressComponent[i].types[0] == "locality") {
                    this.shippingAddressData.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.shippingAddressData.postCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.shippingAddressData.stateCode = addressComponent[i].short_name;
                }
            }
        },
        setShippingAddressTwo(locationData) {
            let location = locationData.formatted_address;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.shippingAddressData.addressTwo = locationData.name;
        },
        async getCustomerList() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    // this.userList = await response.data.data;
                    this.userList = await response.data.data.map(el => {
                        return { value: el, label: el.firstName + " " + el.lastName }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        async getShippingAddress(id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.getAddress + id,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.$axios(config).then((response) => {

                if (response.data.statusCode == 200) {
                    if (Object.values(response.data.data).length > 0) {
                        if (Object.values(response.data.data.shippingAddresses).length > 0) {
                            this.shippingAddressData = response.data.data.shippingAddresses[0];
                        } else {
                            this.shippingAddressData = {
                                name: "",
                                addressOne: "",
                                addressTwo: "",
                                city: "",
                                postCode: "",
                                stateCode: "",
                                email: "",
                                mobile: "",
                            }
                        }
                    } else {
                        this.shippingAddressData = {
                            name: "",
                            addressOne: "",
                            addressTwo: "",
                            city: "",
                            postCode: "",
                            stateCode: "",
                            email: "",
                            mobile: "",
                        }
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        },
        async setUserData(data) {
            await this.getShippingAddress(data.id);
            this.userName = data.firstName + ' ' + data.lastName;
            this.userEmail = data.email;
            this.shippingEmail = data.email;
            this.userMobile = data.phone;
            this.userID = data.id;
            this.userDiscountType = data.discountType;
            this.userDiscountAmount = data.discountAmount;
            if (this.isExistingUser == true) {
                // let params = '?page=' + this.page + '&limit=' + this.limit;
                // let url = this.productAuthorizedURL + this.userID + params;
                // await this.getDataList(url);
                this.productList = this.dataList.map(el => {
                    return { value: el, label: el.name }
                })
            }
        },
        addProduct() {
            this.orderConfirmed = false;
            this.idx = this.idx + 1;
            this.addedProductList.push({ productId: "" });
            this.colorValue[this.idx] = '';
        },
        calculateWeight() {
            this.totalWeight = 0;
            for (let i = 0; i < this.selectedProduct.length; i++) {
                this.totalWeight = this.totalWeight + parseFloat(this.selectedProduct[i].weight) * parseFloat(this.selectedProduct[i].quantity);
            }
        },
        productData(data, index) {
            this.orderConfirmed = false;
            if (this.selectedProduct[index] != undefined) {
                data.quantity = 1
                this.selectedProduct[index] = {
                    ...data
                };
                this.priceList[index] = { ...data.price }
            } else {
                const newData = {
                    ...data,
                }
                newData.quantity = 1
                this.selectedProduct.push(newData);
                this.priceList.push(newData.price);
            }
            this.calculateTotal();
        },
        selectColor(index) {
            this.selectedProduct[index].chossedColor = this.colorValue[index];
        },
        increaseQuantity(index) {
            this.selectedProduct[index].quantity = this.selectedProduct[index].quantity + 1;
            this.calculateTotal();
        },
        decreaseQuantity(index) {
            (this.selectedProduct[index].quantity == 1) ? (this.selectedProduct[index].quantity = 1) : (this.selectedProduct[index].quantity = this.selectedProduct[index].quantity - 1);
            this.calculateTotal()
        },
        removeItem(index) {
            this.orderConfirmed = false;
            this.selectedProduct.splice(index, 1);
            this.addedProductList.splice(index, 1);
            this.productValue.splice(index, 1);
            this.colorValue.splice(index, 1);
            this.calculateTotal()
        },
        addDiscount() {
            let rawDiscount = '0';
            if (!this.discount) {
                rawDiscount = '0';
            }
            else {
                rawDiscount = this.discount;
            }
            let discountCheck = '';
            this.finalAmount = this.total;
            if (rawDiscount) {
                if (rawDiscount.slice(-1) == '%') {
                    discountCheck = rawDiscount.slice(0, -1);
                }
            }
            else {
                discountCheck = rawDiscount;
            }
            if (isNaN(discountCheck)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter valid discount amount or percentage"
                });
                rawDiscount = '';
                return;
            }
            if (rawDiscount && !isNaN(discountCheck)) {
                if (rawDiscount.includes("%")) {
                    let converted_discount = parseFloat(rawDiscount) / 100.0;
                    this.manualDiscount = this.finalAmount * converted_discount;
                    this.finalAmount = this.finalAmount - (this.finalAmount * parseFloat(converted_discount));
                }
                else {
                    try {
                        let converted_discount = parseFloat(rawDiscount);
                        this.finalAmount = this.finalAmount - converted_discount;
                        this.manualDiscount = converted_discount;
                    }
                    catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please enter discount amount or percentage"
                        });
                    }
                }
            }
            else {
                this.finalAmount = 0;
            }
        },
        findActualPrice(selectedProduct) {
            let returnPrice = 0;
            let userSpecialDiscountPrice = 0;
            console.log('Actual price = ', selectedProduct.price);
            console.log('Discounted price = ', selectedProduct.discountedPrice);
            console.log('Product wise user discounted price = ', selectedProduct.userDiscountedPrice);
            if (this.userDiscountType == "Amount") {
                userSpecialDiscountPrice = selectedProduct.price - this.userDiscountAmount;
            }
            else if (this.userDiscountType == "Percentage") {
                if (this.userDiscountAmount != 0) {
                    userSpecialDiscountPrice = (selectedProduct.price * this.userDiscountAmount) / 100;
                    userSpecialDiscountPrice = (selectedProduct.price - userSpecialDiscountPrice);
                }
                else {
                    userSpecialDiscountPrice = selectedProduct.price;
                }
            }
            console.log('User discount final price = ', userSpecialDiscountPrice);

            if (selectedProduct.discountedPrice > 0 && selectedProduct.price > selectedProduct.discountedPrice) {
                if (parseFloat(selectedProduct.userDiscountedPrice) > 0) {
                    if (parseFloat(selectedProduct.userDiscountedPrice) > selectedProduct.discountedPrice && parseFloat(userSpecialDiscountPrice) > selectedProduct.discountedPrice) {
                        returnPrice = selectedProduct.discountedPrice;
                    }
                    else if (selectedProduct.discountedPrice > parseFloat(selectedProduct.userDiscountedPrice) && parseFloat(userSpecialDiscountPrice) > parseFloat(selectedProduct.userDiscountedPrice)) {
                        returnPrice = parseFloat(selectedProduct.userDiscountedPrice);
                    }
                    else if (selectedProduct.discountedPrice > parseFloat(userSpecialDiscountPrice) && parseFloat(selectedProduct.userDiscountedPrice) > parseFloat(userSpecialDiscountPrice)) {
                        returnPrice = parseFloat(userSpecialDiscountPrice);
                    }
                }
                // else if (selectedProduct.userDiscountedPrice == null) {
                //     if (parseFloat(userSpecialDiscountPrice) > selectedProduct.discountedPrice) {
                //         returnPrice = selectedProduct.discountedPrice;
                //     }
                //     else if (selectedProduct.discountedPrice > parseFloat(userSpecialDiscountPrice)) {
                //         returnPrice = parseFloat(userSpecialDiscountPrice);
                //     }
                // }
                else if (selectedProduct.userDiscountedPrice == null || selectedProduct.userDiscountedPrice == '') {
                    if (parseFloat(userSpecialDiscountPrice) > selectedProduct.discountedPrice) {
                        returnPrice = selectedProduct.discountedPrice;
                    }
                    else if (selectedProduct.discountedPrice > parseFloat(userSpecialDiscountPrice) && parseFloat(userSpecialDiscountPrice) > 0) {
                        returnPrice = parseFloat(userSpecialDiscountPrice);
                    } else if (parseFloat(userSpecialDiscountPrice) == 0) {
                        returnPrice = selectedProduct.discountedPrice;
                    }
                }
            }
            else if (selectedProduct.discountedPrice == 0) {
                if (parseFloat(selectedProduct.userDiscountedPrice) > 0) {
                    if (parseFloat(userSpecialDiscountPrice) > parseFloat(selectedProduct.userDiscountedPrice)) {
                        returnPrice = parseFloat(selectedProduct.userDiscountedPrice);
                    }
                    else if (parseFloat(selectedProduct.userDiscountedPrice) > parseFloat(userSpecialDiscountPrice)) {
                        returnPrice = parseFloat(userSpecialDiscountPrice);
                    }
                }
                // else if (selectedProduct.userDiscountedPrice == null) {
                //     returnPrice = parseFloat(userSpecialDiscountPrice);
                // }
                else if (selectedProduct.userDiscountedPrice == null || selectedProduct.userDiscountedPrice == '') {
                    if (parseFloat(userSpecialDiscountPrice) == 0) {
                        returnPrice = parseFloat(selectedProduct.price);
                    } else {
                        returnPrice = parseFloat(userSpecialDiscountPrice);
                    }
                }
            }
            else {
                returnPrice = selectedProduct.price;
            }

            // if(selectedProduct.discountedPrice > 0 && selectedProduct.price > selectedProduct.discountedPrice) {
            //     if(selectedProduct.userDiscountedPrice > 0) {
            //         if(selectedProduct.userDiscountedPrice > selectedProduct.discountedPrice && userSpecialDiscountPrice > selectedProduct.discountedPrice) {
            //             returnPrice = selectedProduct.discountedPrice;
            //         }
            //         else if(selectedProduct.discountedPrice > selectedProduct.userDiscountedPrice && userSpecialDiscountPrice > selectedProduct.userDiscountedPrice) {
            //             returnPrice = selectedProduct.userDiscountedPrice;
            //         }
            //         else if(selectedProduct.discountedPrice > userSpecialDiscountPrice && selectedProduct.userDiscountedPrice > userSpecialDiscountPrice) {
            //             returnPrice = userSpecialDiscountPrice;
            //         }
            //     }
            //     else if(selectedProduct.userDiscountedPrice == null) {
            //         if (userSpecialDiscountPrice > selectedProduct.discountedPrice) {
            //             returnPrice = selectedProduct.discountedPrice;
            //         }
            //         else if (selectedProduct.discountedPrice > userSpecialDiscountPrice) {
            //             returnPrice = userSpecialDiscountPrice;
            //         }
            //     }
            // }
            // else if(selectedProduct.discountedPrice == 0) {
            //     if(selectedProduct.userDiscountedPrice > 0) {
            //         if (userSpecialDiscountPrice > selectedProduct.userDiscountedPrice) {
            //             returnPrice = selectedProduct.userDiscountedPrice;
            //         }
            //         else if (selectedProduct.userDiscountedPrice > userSpecialDiscountPrice) {
            //             returnPrice = userSpecialDiscountPrice;
            //         }
            //     }
            //     else if(selectedProduct.userDiscountedPrice == null) {
            //         returnPrice = userSpecialDiscountPrice;
            //     }
            // }
            // else {
            //     returnPrice = selectedProduct.price;
            // }
            console.log('Return price = ', parseFloat(returnPrice));
            return parseFloat(returnPrice);
        },
        calculateTotal() {
            this.subTotal = 0;
            this.total = 0;
            this.totalDiscount = 0;
            for (let el in this.selectedProduct) {
                if (this.selectedProduct[el].price && !isNaN(this.selectedProduct[el].price)) {
                    if (this.selectedProduct[el].discountPrice !== '0.00') {
                        this.subTotal = this.subTotal + this.selectedProduct[el].discountPrice * this.selectedProduct[el].quantity;
                    } else {
                        this.subTotal = this.subTotal + this.selectedProduct[el].price * this.selectedProduct[el].quantity;
                    }
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter valid amount"
                    });
                    this.selectedProduct[el].price = this.priceList[el];
                    this.calculateTotal();
                }

            }
            // if (this.discount) {
            //     this.addDiscount();
            // }
            this.calculateWeight();
        },
        async getServiceList() {
            if (this.validation())
                await this.checkShipping();
        },
        async getUpsServiceList() {
            if (this.validation())
                await this.upsCheckShipping();
        },
        async getLocalServiceList() {
            if (this.validation())
                await this.localCheckShipping();
        },
        setShippingCost(price) {
            this.shippingCost = parseFloat(price);
        },
        async checkShipping() {
            this.serviceTypeList = [];
            this.shippingAddressData.country = this.shippingCountry.name;
            this.shippingAddressData.countryCode = this.shippingCountry.code;
            let data = {
                shippingAddress: this.shippingAddressData,
                weightValue: 50 //Math.ceil(this.totalWeight)
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.checkShippingURL,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.$swal.showLoading();
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                this.$swal.close();
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.errors[i].message
                            });
                        }
                    }
                }
                else if (response.status == 200) {
                    this.serviceTypeList = response.data;
                }

            }).catch(error => {
                this.$swal.hideLoading();
                this.$swal.close();
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        async upsCheckShipping() {
            this.serviceTypeList = [];
            let data = {
                shippingAddress: this.shippingAddressData,
                weightValue: this.totalWeight
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.upsCheckShippingURL,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.$swal.showLoading();
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                this.$swal.close();
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.response.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.response.errors[i].message
                            });
                        }
                    }
                }
                else if (response.status == 200) {
                    this.serviceTypeList = response.data;
                    this.shippingType = 'ups';
                }

            }).catch(error => {
                this.$swal.hideLoading();
                this.$swal.close();
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },

        async localCheckShipping() {
            this.serviceTypeList = [];
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.shippingMethods.requestURL,
                headers: {
                    "Authorization": authHeader()
                }
            };

            await this.$axios(config).then((response) => {

                this.$swal.close();
                if (response.data.statusCode == 200) {
                    this.shippingMethodList = response.data.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },

        async shippingCms() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.ComponentCms.shippingCmsURL,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.$swal.showLoading();
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                this.$swal.close();
                if (response.data.statusCode == 200) {
                    this.shippingCmsData = response.data.data.length > 0 ? response.data.data[0] : {};
                }
            }).catch(error => {
                this.$swal.hideLoading();
                this.$swal.close();
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },


        validation() {
            if (!this.userName) {
                this.shippingType = null;
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide customer name.",
                });
                return false;
            } else if (!this.userEmail) {
                this.shippingType = null;
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide customer email.",
                });
                return false;
            } else if (!this.userMobile) {
                this.shippingType = null;
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide customer Mobile.",
                });
                return false;
            }
            // else if (this.shippingAddressData.phone.length < 9) {
            //     console.log(this.shippingAddressData.phone)
            //     this.$swal.fire({
            //         icon: "info",
            //         text: "Mobile number must be at least 10 digit",
            //     });
            //     return false;
            // } 
            else if (!this.shippingAddressData.name) {
                this.shippingType = null;
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping name.",
                });
                return false;
            } else if (!this.shippingAddressData.email) {
                this.shippingType = null;
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping email.",
                });
                return false;
            }
            else if (!this.shippingAddressData.phone) {
                this.shippingType = null;
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping contact no.",
                });
                return false;
            }
            else if (this.shippingAddressData.phone.length < 9) {
                this.shippingType = null;
                this.$swal.fire({
                    icon: "info",
                    text: "Mobile number must be at least 10 digit",
                });
                return false;
            } else if (!this.shippingAddressData.addressOne) {
                this.shippingType = null;
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping address1.",
                });
                return false;
            } else if (!this.shippingAddressData.city) {
                this.shippingType = null;
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping city.",
                });
                return false;
            } else if (!this.shippingAddressData.postCode) {
                this.shippingType = null;
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping post code.",
                });
                return false;
            } else {
                return true;
            }
        },

        setPaymentAmount() {
            this.paymentInfo.amount = parseFloat(this.subTotal + this.shippingCost + this.taxAmount).toFixed(2);
        },
        formatCCnum() {

            if (this.paymentInfo.cardNumber.length == 4) {

                this.paymentInfo.cardNumber += " ";

            }

            if (this.paymentInfo.cardNumber.length == 9) {

                this.paymentInfo.cardNumber += " ";

            }

            if (this.paymentInfo.cardNumber.length == 14) {

                this.paymentInfo.cardNumber += " ";

            }

        },
        handleExpireDate() {
            if (this.paymentInfo.expireDate.length == 2) {
                this.paymentInfo.expireDate += '/';
            }
        },
        paymentValidation() {
            if (!this.paymentInfo.name) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card holder name."
                });
                return false;
            } else if (!this.paymentInfo.cardNumber) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card number."
                });
                return false;
            } else if (!this.paymentInfo.expireDate) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card expire date."
                });
                return false;
            } else if (!this.paymentInfo.securityCode) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card security code."
                });
                return false;
            } else {
                if (!this.paymentInfo.expireDate.includes('/')) {
                    this.$swal.fire({
                        icon: 'info',
                        text: "Expire date format should be 02/22."
                    });
                    return false;
                }
                return true;
            }
        },
        async handlePayment(payment) {
            if (payment == 'Pay Later') {
                this.createOrder('Pay Later');
            } else if (payment == 'Pay Now') {
                if (this.paymentValidation()) {
                    this.createOrder('Pay Now');
                }
            } else {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select payment option."
                });
                return;
            }
        },
        confirmOrder() {
            this.orderConfirmed = false;
            if (!this.selectedProduct.length) {
                this.$swal.fire({
                    icon: "error",
                    text: `Please choose products first!`
                });
                document.getElementById('buttonClose').click();
                this.orderConfirmed = false;
                return false;
            } else {
                for (const product of this.selectedProduct) {
                    if (!product.chossedColor) {
                        this.$swal.fire({
                            icon: "error",
                            text: `Please choose color for selected products!`
                        });
                        document.getElementById('buttonClose').click();
                        this.orderConfirmed = false;
                        return false;
                    }
                }
            }
            if (!this.shippingType) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select shipping type.",
                });
                this.orderConfirmed = false;
                return;
            }
            if (!this.serviceType) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select service type.",
                });
                this.orderConfirmed = false;
                return;
            }
            this.orderConfirmed = true;
        },
        async createOrder(paymentType) {
            if (paymentType == 'Pay Later') {
                this.paymentInfo.name = "";
                this.paymentInfo.cardNumber = "";
                this.paymentInfo.securityCode = "";
                this.paymentMethodName = "Pay with cash";
                this.paymentStatus = "PENDING";
            } else {
                this.paymentMethodName = "Credit/Debit Card";
                this.paymentStatus = "Paid";
            }

            this.confirmedProduct = this.selectedProduct.map((el) => {
                return {
                    id: el.id,
                    name: el.name,
                    featuredImage: el.featuredImage,
                    quantity: el.quantity,
                    price: el.discountPrice !== '0.00' ? Number(el.discountPrice) : Number(el.price),
                    itemSubTotal: el.discountPrice !== '0.00' ? Number(el.discountPrice) * el.quantity : Number(el.price) * el.quantity,
                    productColor: el.chossedColor
                };
            });

            for (const product of this.confirmedProduct) {
                if (!product.productColor) {
                    this.$swal.fire({
                        icon: "error",
                        text: `Please choose color for selected products!`
                    });
                    document.getElementById('buttonClose').click();
                    return false;
                }
            }

            let data = {
                // orderId: this.finalOrderID,
                customerName: this.userName,
                email: this.userEmail,
                contactNo: this.userMobile,
                shippingAddress: {
                    name: this.shippingAddressData.name,
                    mobile: this.shippingAddressData.phone,
                    email: this.shippingAddressData.email,
                    city: this.shippingAddressData.city,
                    postCode: this.shippingAddressData.postCode,
                    stateCode: this.shippingAddressData.stateCode,
                    country: this.shippingCountry.name,
                    countryCode: this.shippingCountry.code,
                    addressOne: this.shippingAddressData.addressOne,
                    addressTwo: this.shippingAddressData.addressTwo,
                },
                paymentMethodName: this.paymentMethodName,
                paymentStatus: this.paymentStatus,
                shippingType: this.shippingType,
                shippingCost: this.shippingCost,
                serviceType: this.serviceType,
                weightValue: this.totalWeight,
                products: this.confirmedProduct,
                remarks: this.remarks,
                itemSubTotal: this.subTotal,
                // itemTotal: this.total,
                ccNum: this.paymentInfo.cardNumber.split(' ').join(''),
                ccExp: this.paymentInfo.expireDate ? this.paymentInfo.expireDate.replace('/', '') : '',
                cvv: this.paymentInfo.securityCode,
                amount: this.paymentInfo.amount ? this.paymentInfo.amount : 0,
                itemTotal: (parseFloat(this.total + this.shippingCost + this.taxAmount) - parseFloat(this.manualDiscount)).toFixed(2),
                salesTaxTitle: this.salesTax.name,
                salesTaxRate: this.salesTax.salesTax,
                salesTaxAmount: this.taxAmount,
                totalDiscount: (this.totalDiscount + parseFloat(this.manualDiscount)).toFixed(2)
            }
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.createOrderURL,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.$swal.showLoading();
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.data.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    document.getElementById('buttonClose').click()
                    this.$router.push('/order-list');
                } else if (response.data.statusCode == 400) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    }).then(result => {
                        console.log(result);
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.errors.responsetext,
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        })
                    });
                }
            }).catch(error => {
                this.$swal.hideLoading()
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });

            //}
        },
        calculateTax() {
            this.taxAmount = (this.subTotal * (parseFloat(this.salesTax.salesTax) / 100));
            return this.taxAmount.toFixed(2);
        },
    },
    watch: {
        isExistingUser: function () {
            if (this.isExistingUser == false) {
                this.userDetails = ""
                this.userName = "";
                this.userEmail = "";
                this.userMobile = "";
                this.shippingName = "";
                this.shippingContactNo = "";
                this.shippingAddressData = {};
                this.userID = "";
                this.userDiscountType = "";
                this.userDiscountAmount = "";
            }
            // else if(this.isExistingUser == true) {
            //     console.log("Is existing user: ", this.isExistingUser)
            //     this.productList = [];
            //     this.addedProductList = [{
            //         productId: ""
            //     }]
            // }
        },
        discount: async function () {
            // console.log("Manual discount in watch: ", this.discount);
            this.addDiscount();
        }
    }
}