<template>
    <div class="page-wrapper">
      <div class="page-content">
        <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div class="breadcrumb-title pe-3">Home</div>
          <div class="ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item">
                  <router-link to="/home">
                    <i class="bx bx-home-alt"></i>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Home Page</li>
              </ol>
            </nav>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Home Component UI CMS</h5>
            <hr />
            <div class="form-body mt-4" v-if="dataLoaded">
                <div class="row mb-3">
                    <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Page Background Color:"
                      v-model="cmsData.backgroundColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                </div>
                <hr/>
              <div class="row mb-3">
                <div class="row mb-2">
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Hero Title Font Color"
                        v-model="cmsData.heroTitleColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Hero Button Background Color"
                        v-model="cmsData.heroButtonBackgroundColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Hero Button Hover Background Color"
                        v-model="cmsData.heroButtonHoverBackgroundColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                </div>
                <hr/>

                <div class="row mb-2">
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="About Heading Color"
                        v-model="cmsData.aboutHeadingColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="About Title Color"
                        v-model="cmsData.aboutTitleColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="About Button Text Color"
                        v-model="cmsData.aboutbuttonTextColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="About Button Background Color"
                        v-model="cmsData.aboutbuttonBgColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="About Button Hover Background Color"
                        v-model="cmsData.aboutButtonHoverBgColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="About Image Text One Color"
                        v-model="cmsData.aboutImageTextOneColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                </div>
                <hr/>

                <div class="row mb-2">
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Bannar Title Color"
                        v-model="cmsData.bannerTitleColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Bannar Button Text Color"
                        v-model="cmsData.bannerButtonTextColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Bannar Button Bg Color"
                        v-model="cmsData.bannerButtonBgColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                </div>
                <hr/>

                <div class="row mb-2">
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Discover Section Title Color"
                        v-model="cmsData.discoverSectionTitleColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Discover Section Background Color"
                        v-model="cmsData.discoverSectionBgColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                </div>
                <hr/>
                <div class="row mb-2">
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Our Product Section Heading Font Color"
                        v-model="cmsData.ourProductHeadingColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Our Product Title Color"
                        v-model="cmsData.ourProductTitleColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                </div>
                <hr/>

                <div class="row mb-2">
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Package Title Color"
                        v-model="cmsData.packageTitleColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Package Subtitle Color"
                        v-model="cmsData.packageSubTitleColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                </div>
                <hr/>

                <div class="row mb-2">
                    <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Blog Heading Color"
                        v-model="cmsData.blogHeadingColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Blog Title Color"
                        v-model="cmsData.blogTitleColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                </div>
                <hr/>

                <div class="row mb-2">
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Testimonial Section Background Color"
                        v-model="cmsData.testimonialSectionBackgroundColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Testimonial Title Color"
                        v-model="cmsData.testimonialTitleColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Testimonial Description Color"
                        v-model="cmsData.testimonialDescriptionColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                </div>
                <hr/>

                <div class="row mb-2">
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Instagram Heading Color"
                        v-model="cmsData.instagramHeadingColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="input-group">
                      <LvColorpicker
                        label="Instagram Title Color"
                        v-model="cmsData.instagramTitleColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                      />
                    </div>
                  </div>
                </div>
                
             </div>
              <div class="row mt-3">
                <div class="d-grid">
                  <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                    <button class="btn btn-primary" @click="update" type="button">Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script src="../js/home-component-ui.js"></script>