import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';
import AddDiscountModal from "../template/AddDiscountModal";

export default {
    name: "Customers List",
    components: {
        AddDiscountModal
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            phone: null,
            userType: 'customer',
            password: null,
            confirmPassword: null,
            inputTypePassword: "password",
            inputTypeConfirmPassword: "password",
            iconStatus: true,
            iconFlag: true,
            gender: '',
            singleCustomer: {},
            discount: "",
            sheets: [],
            userData: '',
            dataList: []
        }
    },
    async created() {
        document.title = "Ganja Containers - Customer";
        await this.getDataList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        validateEmail: function (email){
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        getDataList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.customerUserList,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        async userInfo(e) {
            this.userData = e;
        },
        addCustomer: async function () {
            if (!this.firstName || !this.lastName || !this.email || !this.phone || !this.password || !this.confirmPassword) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter first name, last name, email and password!"
                });
            } else if(!this.validateEmail(this.email)){
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter valid email!"
                });
            }
            else if (this.password != this.confirmPassword) {
                this.$swal.fire({
                    icon: "error",
                    text: "Password and confirm didn't match!"
                });
            }
            else {
                let data = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    // gender: this.gender,
                    password: this.password,
                    phone: this.phone,
                    userType: this.userType
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.user.regUserURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.postDataToBackend(config);
                this.firstName = '';
                this.lastName = '';
                this.email = '';
                this.phone = '';
                this.userType = 'customer';
                this.password = '';
                this.confirmPassword = '';
                this.inputTypePassword = "password";
                this.inputTypeConfirmPassword = "password";
                await this.getDataList();
            }
        },
        editCustomer: function (data) {
            this.singleCustomer = data;
        },
        updateCustomer: async function () {
            if (!this.singleCustomer.firstName || !this.singleCustomer.lastName || !this.singleCustomer.phone) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter first name, last name, and phone!"
                });
            }
            else {
                let data = {
                    firstName: this.singleCustomer.firstName,
                    lastName: this.singleCustomer.lastName,
                    // gender: this.singleCustomer.gender,
                    phone: this.singleCustomer.phone,
                    discountType: this.singleCustomer.discountType,
                    discountAmount: this.singleCustomer.discountAmount
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.user.singleUserDetails + this.singleCustomer.id,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
            }
        },
        changePasswordType: function () {
            if (this.inputTypePassword == "password") {
                this.inputTypePassword = "text";
                this.iconStatus = false;
            }
            else if (this.inputTypePassword == "text") {
                this.inputTypePassword = "password";
                this.iconStatus = true;
            }
        },
        changeConfirmPasswordType: function () {
            if (this.inputTypeConfirmPassword == "password") {
                this.inputTypeConfirmPassword = "text";
                this.iconFlag = false;
            }
            else if (this.inputTypeConfirmPassword == "text") {
                this.inputTypeConfirmPassword = "password";
                this.iconFlag = true;
            }
        },
        deleteCustomer: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.user.singleUserDetails + id,
                            headers: {
                                "Authorization": authHeader()
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try again later."
                        });
                    }
                }
            })
        },
        exportUsers: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Customer Name": el.firstName + " " + el.lastName,
                    "Mobile": el.phone,
                    "Email": el.email,
                    "Gender": el.gender,
                    "Date of Birth": this.$filters.formatCouponDate(el.dateOfBirth),
                    "Address": el.fullAddress,
                    "City": el.city,
                    "Post Code": el.postalCode,
                    "Country": el.country,
                    "Created At": this.$filters.formatDate(el.createdAt)
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets);
            const wb = XLSX.utils.book_new();
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            XLSX.writeFile(wb, 'customer_report(' + date + ').xlsx');
        }
    }
}