import { authHeader } from '../../../../auth';

export default {
    name: "SocialLinks",
    data() {
        return {
            icon: "",
            link: "",

            singleSocialLink: {},

            previewImage: "",
            previewUpdateImage: "",

            setURL: this.$serverURL + this.$api.socialLink.socialLinkUrl,
            dataList: []
        }
    },
    async created() {
        document.title = "Ganja Containers - Social Links";
        await this.getData();
        this.loadCss();
    },
    methods: {
        loadCss() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },

        async getData() {
            await this.getDataList(this.setURL);
        },

        handleUploadImage(event) {
            this.icon = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        handleUploadUpdateImage(event) {
            this.singleSocialLink.icon = event.target.files[0];
            let input = this.$refs.updateImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewUpdateImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },

        validate() {
            if (!this.icon) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter social media icon!"
                });
                return false;
            } else if (!this.link) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter social media link!"
                });
                return false;
            }
            return true;
        },

        addSocialLinks: async function () {
            if (this.validate()) {
                const formData = new FormData();
                formData.append('icon', this.icon);
                formData.append('link', this.link);
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.postDataToBackend(config);
                this.icon = "";
                this.link = "";
                this.$refs.addImage.value = null;
                this.previewImage = "";
                await this.getData();
                document.getElementById('addButtonClose').click();

            }
        },

        editSocialLink: async function (data) {
            this.singleSocialLink = data;
        },

        validateUpdateCred() {
            if (!this.singleSocialLink.icon) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter social media icon!"
                });
                return false;
            } else if (!this.singleSocialLink.link) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter social media link!"
                });
                return false;
            }
            return true;
        },

        updateSocialLinks: async function () {
            if (this.validateUpdateCred()) {
                const formData = new FormData();
                formData.append('icon', this.singleSocialLink.icon);
                formData.append('link', this.singleSocialLink.link);
                let config = {
                    method: "PATCH",
                    url: this.setURL + '/' + this.singleSocialLink.id,
                    data: formData,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                this.singleSocialLink = {};
                this.$refs.updateImage.value = null;
                this.previewUpdateImage = "";
                await this.getData();
                document.getElementById('editButtonClose').click();
            }
        },

        deleteSocialLinks: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + '/' + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getData();

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })

        },
    },

}