<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">About Us</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Section One CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Section One CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-6 mb-3">
                                <div class="row mb-3">
                                    <div class="col-md-12">
                                        <label class="form-label" for="title">Title:</label>
                                        <input type="text" class="form-control" v-model="cmsData.title"
                                            id="title" />
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-12">
                                        <label class="form-label" for="title">Subtitle:</label>
                                        <textarea type="text" class="form-control" v-model="cmsData.subTitle"
                                            id="title"></textarea>
                                    </div>
                                </div>
                                <div>
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="form-label">
                                            Image One:
                                            </label>
                                        </div>
                                        <div class="col-12">
                                            <img
                                            style="position: relative; left: 11px"
                                            v-if="preview.imageOne"
                                            :src="preview.imageOne"
                                            height="70"
                                            />
                                            <img
                                            style="position: relative; left: 11px"
                                            v-else-if="cmsData.imageOne"
                                            :src="cmsData.imageOne"
                                            height="70"
                                            />
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <input
                                        class="form-control mt-3"
                                        type="file"
                                        ref="imageOne"
                                        accept="image/*"
                                        @change="handleUploadImage('imageOne','imageOne','imageOne', $event)"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="form-label">
                                            Image Two:
                                            </label>
                                        </div>
                                        <div class="col-12">
                                            <img
                                            style="position: relative; left: 11px"
                                            v-if="preview.imageTwo"
                                            :src="preview.imageTwo"
                                            height="70"
                                            />
                                            <img
                                            style="position: relative; left: 11px"
                                            v-else-if="cmsData.imageTwo"
                                            :src="cmsData.imageTwo"
                                            height="70"
                                            />
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <input
                                        class="form-control mt-3"
                                        type="file"
                                        ref="imageTwo"
                                        accept="image/*"
                                        @change="handleUploadImage('imageTwo','imageTwo','imageTwo', $event)"
                                        />
                                    </div>
                                </div>
                                <div class="d-grid mt-4">
                                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/section-one-cms.js"></script>