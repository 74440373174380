import { authHeader } from "../../../../auth";
import { countries } from "../../../../../config/country";
export default {
    data() {
        return {
            setURL: this.$serverURL + this.$api.shippingConfig.upsConfigURL,
            countryList: countries,
            pageCMSData: [],
            imagePath: this.$imageURL + 'location-cms/',
            previewImage: '',
            credential: "sandbox",
            isLive: false,
            isSandbox: true,
            showPass: false,
            country: {
                name: 'United States of America',
                code: 'US',
            },
        }
    },
    async mounted() {
        await this.getCmsData();
    },
    methods: {
        async getCmsData() {
            let config = {
                method: 'GET',
                url: this.setURL,
                headers: {
                    "Authorization": authHeader(),
                    "Content-Type": "application/json",
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.pageCMSData = response?.data?.data[0]? response?.data?.data[0] : {
                        sandboxAccessLicenseNumber:"",
                        addressLine:"",
                        shipperAccountName:"",
                        shipperAccountNumber:"",
                        city:"",
                        stateProvienceCode:"",
                        postalCode:"",
                    };
                    if(!response.data.data[0]){
                        this.credential = 'sandbox'
                        this.pageCMSData.sandboxUrl= ''
                        this.pageCMSData.sandboxUserName=''
                        this.pageCMSData.sandboxPassword=''
                        this.country={
                            code:'',
                            name:''
                        }
                        
                    }
                    this.credential = this.pageCMSData.credentialType
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again later!"
                });
            });
        },
        async update() {
            try {
                let data = {
                    credentialType: this.credential,
                    accessLicenseNumber: this.credential == 'sandbox' ? this.pageCMSData.sandboxAccessLicenseNumber : this.pageCMSData.liveAccessLicenseNumber,
                    url: this.credential == 'sandbox' ? this.pageCMSData.sandboxUrl : this.pageCMSData.liveUrl,
                    userName: this.credential == 'sandbox' ? this.pageCMSData.sandboxUserName : this.pageCMSData.liveUserName,
                    password: this.credential == 'sandbox' ? this.pageCMSData.sandboxPassword : this.pageCMSData.livePassword,
                    addressLine: this.pageCMSData.addressLine,
                    shipperAccountName: this.pageCMSData.shipperAccountName,
                    shipperAccountNumber: this.pageCMSData.shipperAccountNumber,
                    city: this.pageCMSData.city,
                    stateProvienceCode: this.pageCMSData.stateProvienceCode,
                    postalCode: this.pageCMSData.postalCode,
                    countryCode: this.country.code,
                    country: this.country.name
                }
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "application/json",
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201 || response.status == 200) {
                        this.$swal({
                            icon: 'success',
                            text: response.data.message
                        })
                        this.getCmsData();
                    }
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again later!"
                    });
                });

                //}
            } catch (error) {
                console.log(error)
            }
        },
    }
}