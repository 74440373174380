import { authHeader } from "../../../../auth";
export default {
    name: "ImportProduct",
    data() {
        return {
            productList: [],
            dumpID: null
        }
    },
    async created() {
        document.title = "Ganja Containers -Import Products";
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        excelImport: async function (event) {
            let excelFile = await event.target.children[0].files ? event.target.children[0].files[0] : null;
            if (!excelFile) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please choose file!"
                });
            }
            else {
                const formData = new FormData();
                formData.append('excelFile', excelFile);
                const headers = { 'Content-Type': 'multipart/form-data' };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.product.productBulkUpload,
                    header: headers,
                    data: formData
                };

                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        this.productList = response.data.product_dump.dump;
                        this.dumpID = response.data.product_dump.dumpID
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                        this.$refs.resetForm.reset();
                    }
                    else {
                       
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again.Error:"+error
                    });
                });
            }
        },
        confirmExcelImport: async function () {
            this.$swal.fire({
                title: 'Do you want to import these products?',
                text: "",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (!this.dumpID) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please Upload file again!"
                        });
                    } else {

                        try {
                            let data = {
                                dumpID: this.dumpID
                            }
                            let config = {
                                method: "POST",
                                url: this.$serverURL + this.$api.product.confirmBulkUpload,
                                data: data,
                                header: authHeader()
                            };
                            await this.$axios(config).then((response) => {
                                console.log(response.data)
                                if (response.data.statusCode == 200) {
                                    this.productList = [];
                                    this.dumpID = null;
                                    this.$swal.fire({
                                        icon: "success",
                                        text: response.data.message
                                    });
                                    this.$router.push('/product-list')
                                }
                            }).catch(error => {
                                this.$swal.fire({
                                    icon: "error",
                                    text: error.response.data.message,
                                });
                            });
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong. Please try again!",
                            });
                        }
                    }
                }
            })
        },
    }
}
